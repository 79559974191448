import React, {Component} from 'react'
import {Modal, Form} from 'react-bootstrap'
import {FaFacebookSquare, FaEye, FaEyeSlash} from 'react-icons/fa'

import {auth, createUserProfileDocument, signInWithFacebook} from "../../firebase/firebase.utils";
import {Link} from "react-router-dom";
import language from "../../assets/language";

const regExp = RegExp(
    /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
)

const formValid = ({isError, ...rest}) => {
    let isValid = false;

    Object.values(isError).forEach(val => {
        if (val.length > 0) {
            isValid = false
        } else {
            isValid = true
        }
    });

    Object.values(rest).forEach(val => {
        if (val === null) {
            isValid = false
        } else {
            isValid = true
        }
    });

    return isValid;
};


class RegisterModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            terms: true,
            news: true,
            showPassword: false,
            isError: {
                email: '',
                password: '',
                terms: '',
                news: '',
                terms_news: '',
            }
        }
    }

    formValChange = e => {
        e.preventDefault();
        const {name, value} = e.target;
        let isError = {...this.state.isError};

        switch (name) {
            case "email":
                isError.email = regExp.test(value) ? "" : language.errors.incorrect_email;
                break;
            case "password":
                isError.password = value.length < 6 ? language.errors.six_characters : "";
                break;
            default:
                break;
        }

        this.setState({
            isError,
            [name]: value
        })
    };

    handleCheckbox = event => {
        let isError = {...this.state.isError};
        const target = event.target;
        const value = target.checked;
        const name = target.name;

        if (value === true) {
            isError.news = '';
            this.setState({
                isError
            });
        }

        this.setState({
            [name]: value
        });
    }

    tooglePasswordVisibility = () => {
        const {showPassword} = this.state;

        this.setState({showPassword: !showPassword})
    }

    onSubmit = async e => {
        e.preventDefault();
        const {email, password, terms, news, isError} = this.state;
        let isValid = true;

        if (email === "") {
            isError.email = language.errors.field_required
            isValid = false
        }

        if (password === "") {
            isError.password = language.errors.field_required
            isValid = false
        }

        if (terms === false || news === false) {
            isError.news = language.errors.check_field
            isValid = false
        }

        if (!isValid) {
            this.setState({
                isError
            })
        }
        if (formValid(this.state) && isValid) {

            try {
                const {user} = await auth.createUserWithEmailAndPassword(email, password)
                await createUserProfileDocument(user)

                this.state = {
                    email: '',
                    password: '',
                    terms: '',
                    isError: {
                        email: '',
                        password: '',
                        terms: '',
                    }
                }

                this.props.onHide(); // ascund modalul dupa register
                this.props.showRegisterSuccessModal();
            } catch (error) {

                if (error.code === "auth/email-already-in-use") {
                    isError.email = language.errors.email_already_in_use
                }else{
                    isError.email = language.errors.error_occured
                }

                this.setState({
                    isError
                })
            }
        }
    };

    onSubmitFacebook = () => {
        signInWithFacebook();
        this.props.onHide();
    }

    render() {
        const {setLoginModal, show, onHide} = this.props;
        const {isError, showPassword} = this.state;


        return (
            <Modal
                show={show}
                onHide={onHide}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modal-register"
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <h2 className='title-modal'>{language.buttons.register_and_play}</h2>
                    <Form onSubmit={this.onSubmit} noValidate>
                        <Form.Group>
                            <Form.Control type="email" name="email"
                                          defaultValue={this.state.email}
                                          onBlur={this.formValChange} placeholder={language.email}
                                          className={isError.email.length > 0 ? "is-invalid form-control input-modal" : "form-control input-modal"}
                            />
                            {isError.email.length > 0 && (
                                <span className="invalid-feedback">{isError.email}</span>
                            )}
                        </Form.Group>
                        <Form.Group className="mt-24">
                            <i className="password-icon" onClick={this.tooglePasswordVisibility}>
                                {showPassword ? <FaEyeSlash size="25px"/> : <FaEye size="25px"/> }
                            </i>
                            <Form.Control type={showPassword ? 'text' : 'password'}
                                          name="password"
                                // defaultValue={this.state.password}
                                          className={isError.password.length > 0 ? "is-invalid form-control input-modal" : "form-control input-modal"}
                                          onBlur={this.formValChange}
                                          placeholder={language.password}/>

                            {isError.password.length > 0 && (
                                <span className="invalid-feedback">{isError.password}</span>
                            )}
                        </Form.Group>
                        <Form.Group className={isError.terms.length > 0 ? "" : "mb-0"} controlId={"terms"}>
                            <Form.Check type="checkbox"
                                                label={language.i_have_read_terms}
                            name="terms"
                            className={isError.terms.length > 0 ? "is-invalid form-check-modal mt-m" : "form-check-modal mt-m"}
                            onClick={this.handleCheckbox}
                            defaultChecked={true}
                            />
                            {isError.terms.length > 0 && (
                                <span className="invalid-feedback">{isError.terms}</span>
                            )}
                        </Form.Group>
                        <Form.Group style={{marginTop:'10px'}} className={isError.news.length > 0 ? "" : "mb-0"} controlId={"news"}>
                            <Form.Check type="checkbox"
                                        label={language.i_want_info}
                                        name="news"
                                        className={isError.news.length > 0 ? "is-invalid form-check-modal" : "form-check-modal"}
                                        onClick={this.handleCheckbox}
                                        defaultChecked={true}
                            />
                            {isError.news.length > 0 && (
                                <span className="invalid-feedback">{isError.news}</span>
                            )}
                        </Form.Group>
                        <div className="row">
                            <div className="col-md-12 text-right">
                                <button type="submit" className="login-btn">{language.buttons.register}</button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-right">
                                <a href="#" className="login-Facebook" onClick={this.onSubmitFacebook}><span className="text-Facebook">{language.login_Facebook}</span> <span className='i-Facebook'><FaFacebookSquare/></span></a>
                            </div>
                        </div>
                        <div className="row row-out">
                            <p className="account-out">{language.account_already}</p>
                            <a className="link-join text-inherit" onClick={setLoginModal}>{language.buttons.login}</a>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        );
    }
}

export default RegisterModal


