import React, {Component} from 'react'
import {Button, Modal, Form} from 'react-bootstrap'
import {FaFacebookSquare} from 'react-icons/fa'

import {signInWithFacebook} from "../../firebase/firebase.utils";
import language from "../../assets/language";

class InfoModal extends Component {

    onSubmit = async e => {
        e.preventDefault();
    };

    onSubmitFacebook = () => {
        signInWithFacebook();
        this.props.onHide();
    }

    render() {
        const {setLoginModal, setRegisterModal, show, onHide} = this.props;

        return (
            <Modal
                show={show}
                onHide={onHide}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modal-register"
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <h2 className="title-modal">{language.buttons.register_and_play}</h2>
                    <Form onSubmit={this.onSubmit} noValidate>
                        <Form.Group>
                            <div>{language.text_info_modal}</div>
                        </Form.Group>
                        <Form.Group controlId="formBasicCheckbox" className="text-center">
                            <Button variant="primary" type="button" className="button-register"
                                    onClick={setRegisterModal}>
                                Sign Up
                            </Button>
                            <Form.Text className="text-muted f-10">
                                {language.account_already} <a href={'#'} onClick={setLoginModal}>{language.buttons.login}</a>
                            </Form.Text>
                        </Form.Group>
                        <Form.Group controlId="formBasicCheckbox" className="text-center mobile">
                            <Form.Text className="text-muted">
                                {language.buttons.login}
                            </Form.Text>
                            <div className="row">
                                <div className="col-md-12 text-right">
                                    <a href='#' onClick={this.onSubmitFacebook}
                                            className="login-Facebook">
                                        <span className="text-Facebook">{language.login_Facebook}</span>
                                        <span className='i-Facebook'><FaFacebookSquare/></span>
                                    </a>
                                </div>
                            </div>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
        );
    }
}

export default InfoModal


