import React from 'react'
import {Helmet} from "react-helmet";

const NotFound = () => (
    <div className='gameplay__container' style={{color: 'white'}}>
        <Helmet>
            <title>Page not found</title>
        </Helmet>

        <div className='container text-center' style={{fontSize: 24}}>
                Page not found
        </div>
    </div>

)

export default NotFound
