import React from 'react'
import {Link} from "react-router-dom";

const GameSlider = ({ thumbnailUrl, id }) => (
    <div>
        <div className='game-img'>
            <Link to={`/game/` + id}>
                <img src={"https:" + thumbnailUrl} alt=''/>
            </Link>
        </div>
    </div>
)

export default GameSlider
