import React from 'react'
import { Modal, Form} from 'react-bootstrap'
import language from "../../assets/language";

const RegisterSuccessModal = ({show, onHide}) => {
        return (
            <Modal
                show={show}
                onHide={onHide}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modal-register"
                id="register-success"
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <h2>{language.register_success_title}</h2>
                    <Form noValidate>
                        <Form.Group>
                            <div className='text-center'>{language.register_success}</div>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
        );
}

export default RegisterSuccessModal


