import React from 'react'
import logo from '../assets/imgs/logo.svg'
import {Link} from "react-router-dom";
import language from '../assets/language'

const Navbar = ({ currentUser, loginButton, registerButton, infoButton, signOut }) => {
  return (
    <div className='navbar__container'>
        <div className="row">
            <div className="logo-container col-sm-6 col-md-6 col-xl-6">
                <Link to={`/`}><img className='logo' src={logo} alt='logo'/></Link>
            </div>
            {
                currentUser ?
                    <div className="col-sm-6 col-md-6 col-xl-6 button-container mt-1">
                        <div className="d-inline-block">
                            <a className='button-login' onClick={() => signOut()}>{language.buttons.logout}</a>
                        </div>
                    </div>
                    :
                    <div className="col-sm-6 col-md-6 col-xl-6 button-container">
                        {/*<div className="register-container">{loginButton}</div>*/}
                        {/*<div className="d-inline-block">{registerButton}</div>*/}
                    </div>
            }
        </div>

    </div>
  )
}

export default Navbar
