import React from 'react'

const GameProducer = ({ image, classCustom }) => (
    <div className="col-6 col-sm-4 col-md-2 col-lg-2 providers">
        <div className="text-center">
            <img className={classCustom} src={image}/>
        </div>
    </div>
)

export default GameProducer
