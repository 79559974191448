import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const config = {
    apiKey: "AIzaSyBIwNYo4quKhCIMavx1rHg2sURAgxUtjAA",
    authDomain: "hyperino-212aa.firebaseapp.com",
    projectId: "hyperino-212aa",
    storageBucket: "hyperino-212aa.appspot.com",
    messagingSenderId: "412303301011",
    appId: "1:412303301011:web:8bf55e42a2b8e11853f066",
    databaseURL: "https://hyperino-212aa.firebaseio.com"
};

export const createUserProfileDocument = async (userAuth, additionalData) => {
    if(!userAuth) return;

    const userRef = firestore.doc(`users/${userAuth.uid}`); // este o referinta
    const snapShot = await userRef.get(); //reprezinta datele

    if (!snapShot.exists) {
        const { email } = userAuth;
        const createdAt = new Date();

        try{
            await userRef.set({
                email,
                createdAt,
                ...additionalData
            })
        }catch (error) {
            console.log('error creating user', error.message())
        }
    }

    return userRef;
}

firebase.initializeApp(config);

export const  auth = firebase.auth();
export const firestore = firebase.firestore();

const FacebookProvider = new firebase.auth.FacebookAuthProvider();
FacebookProvider.setCustomParameters({ prompt: 'select_account'});
export const signInWithFacebook = () => auth.signInWithPopup(FacebookProvider);

export default firebase;
