import React from 'react'
import { Helmet } from 'react-helmet'
import BackButton from "../components/BackButton";

const PrivacyPolicyEn = () => (
    <div className="mb-5 terms-and-conditions">
        <Helmet>
            <title>Hyperino.social Privacy Policy</title>
        </Helmet>

        <div className='gameplay__container bg-img'>
            <div className='container text-center' style={{fontSize: 24}}>
                <h1>Hyperino.social Privacy Policy</h1>
            </div>
        </div>

        <div className="container">
            <div className="row pb-5 mt-75">
                <div className="col-lg-12 text-left ml-2">
                    <p>Alcanada Media Partners Limited (<strong>&ldquo;we"</strong>, <strong>"us"</strong>, <strong>"our"</strong>) is committed to protecting the privacy of any personal data that you provide to us or that we collect about you (<strong>"Personal Information"</strong>). Personal Information is information which identifies you and/or which relates to you, and may include your name, address, date of birth, gaming history, account details, and any other information that we hold about you. We are a controller (also known as a "data controller") in respect of your Personal Information. Controller is a legal term and means that we are responsible for determining the purposes for which your Personal Information is processed.</p>
                    <p>We will ensure that your Personal Information is processed in compliance with data protection laws and regulations in the countries where we operate. Accordingly, we have set out in this Privacy Policy how we use your Personal Information.</p>
                    <p>We may update this Privacy Policy from time to time, and therefore recommend that you review this Privacy Policy regularly. Where we make any material changes to this Privacy Policy, we will notify you that changes have been made.</p>
                    <p><u>How we collect your Personal Information</u></p>
                    <p>In order for us to be able to operate our websites and mobile applications, you will be asked to provide your Personal Information for the purposes of registering and opening an account with us. You are under no obligation to provide Personal Information to us. However, certain Personal Information is essential in order for us to provide you with our services (for example, enabling you to play the games on the website) and you might not be able to obtain certain services if you choose to withhold information we have requested that is required for those services. We may collect Personal Information through surveys which we, or companies engaged by us for such purpose, undertake. In addition, we collect information about your use of our websites, mobile applications and services. We will also collect other information necessary for us to process your Personal Information for the purposes set out in this Privacy Policy.</p>
                    <p>If you provide your name, address or any other Personal Information on the public area of our websites, we will process this Personal Information also.</p>
                    <p>We may also collect Personal Information from third party service providers, such as fraud prevention companies.</p>
                    <p>Finally, we also use "cookies" which may collect certain information about you, such as your devices' IP addresses, which constitutes Personal Information. You can learn more about the cookies we use in the 'Cookies' section below.</p>
                    <p><u>How will we use your Personal Information?</u></p>
                    <p>In accordance with data protection laws, we will only process your Personal Information where we have a lawful basis for doing so. In respect of your Personal Information, these bases are: (i) where it is necessary to provide services to you under the performance of the contract we have with you; (ii) where we are required to do so in accordance with legal or regulatory obligations; (iii) where such processing is in the public interest; (iv) where you have given your consent; and, (v) where it is in our legitimate interests to process your Personal Information, provided that none of these prejudice your own rights, freedoms and interests.</p>
                    <p>The following are a list of the purposes for which we process your Personal Information, and the lawful basis on which we carry out such processing:</p>
                    <table>
                        <tbody>
                        <tr>
                            <td>
                                <p>Purpose</p>
                            </td>
                            <td>
                                <p>Lawful Basis</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>To set-up, administer and manage your account</p>
                            </td>
                            <td>
                                <p>Necessary for the performance of a contract</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>To provide you with our services (including to allow you to play our games)</p>
                            </td>
                            <td>
                                <p>Necessary for the performance of a contract</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>To receive and respond to your communications and requests</p>
                            </td>
                            <td>
                                <p>Necessary for the performance of a contract where such communication relates specifically to our services, otherwise consent or legitimate interests</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>To notify you about updates to our websites, mobile applications and services</p>
                            </td>
                            <td>
                                <p>Necessary for the performance of a contract or legitimate interests</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>To ensure that we are able to fulfil our regulatory obligations regarding your use of our services, including by verifying the accuracy of any information you provide us and by verifying your age (which may involve us disclosing your Personal Information to third parties or supplementing your Personal Information with information received from third parties (such as credit reference agencies)</p>
                            </td>
                            <td>
                                <p>Necessary to comply with a legal or regulatory obligation</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>To prevent or detect unlawful acts or protecting members of the public against dishonesty, malpractice or other seriously improper conduct</p>
                            </td>
                            <td>
                                <p>Necessary to comply with a legal or regulatory obligation or necessary for reasons of substantial public interest</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>To carry out market research campaigns so that we can better understand the products and services that our customers most enjoy</p>
                            </td>
                            <td>
                                <p>Legitimate interests</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>To analyse how you and other players use our websites, mobile applications and services and respond to promotions and adverts, in order to identify relevant patterns and business insights, so that we can improve our products and services and provide tailored offers and recommendations</p>
                            </td>
                            <td>
                                <p>Legitimate interests</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>To build up your personal profile in order to gain a better understanding of your interests in our products and services, to make recommendations for games and other gaming websites.</p>
                            </td>
                            <td>
                                <p>Legitimate interests</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>To send you offers and promotions relating to our services</p>
                            </td>
                            <td>
                                <p>Consent</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>To send you offers about our brands and other brands, provided that you have given your consent (and as more particularly described in the "Marketing" section below)</p>
                            </td>
                            <td>
                                <p>Consent</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>To provide you with ads on social media sites, except where you have requested not to receive such ads via your social media account</p>
                            </td>
                            <td>
                                <p>Legitimate interest</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>To support any other purpose necessary for performance of our contractual obligations or specifically stated at the time at which you provided your Personal Information</p>
                            </td>
                            <td>
                                <p>Necessary for the performance of a contract</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>To record telephone calls to and from, and live chats with, our customer services representatives for training so that we improve our customer services and also for security and identification purposes</p>
                            </td>
                            <td>
                                <p>Legitimate interests in respect of training, and necessary for a legal or regulatory obligation in respect of security and identification</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>To prevent you from using our websites, mobile applications and services if you have requested that we do so</p>
                            </td>
                            <td>
                                <p>Necessary to comply with a legal or regulatory obligation</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>To use your name, image, username or location in publicity and marketing but only where you have provided your express and informed consent for us to do so</p>
                            </td>
                            <td>
                                <p>Legitimate interest</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>Cookies: those that are necessary for the operation of our websites and mobile applications, including allowing you to interact with our websites and mobile applications and to recall selections as you move between pages</p>
                            </td>
                            <td>
                                <p>Necessary for the performance of the contract</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>Cookies: those that analyse your use of our websites and monitor our web audience so we can continue to analyse and improve our website and services, and those that populate certain content on our website in line with your usage so we can better tailor our website to our customers</p>
                            </td>
                            <td>
                                <p>Legitimate interest</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>Cookies: Those that track your journey to and from our website so we can understand how customers come to and from our website and give effect to any commercial arrangements</p>
                            </td>
                            <td>
                                <p>Legitimate interest</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>Cookies: Those that are necessary for regulatory compliance</p>
                            </td>
                            <td>
                                <p>Necessary to comply with a legal or regulatory obligation</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>Cookies: those that are used for third party marketing</p>
                            </td>
                            <td>
                                <p>Legitimate interest</p>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <p><u>Disclosing your Personal Information</u></p>
                    <p>We may also disclose your Personal Information to processors to provide services on our behalf. Such processors will only process your Personal Information in accordance with this Privacy Policy, and we will have a contract in place with each such processor to ensure your Personal Information is kept secure.</p>
                    <p>We may also disclose your Personal Information in the following circumstances:</p>
                    <ul>
                        <li>where required by applicable law or regulation to a governmental, regulatory or enforcement authority;</li>
                        <li>in order to defend ourselves legally and/or in relation to legal proceedings; and&nbsp;</li>
                        <li>if we choose to outsource the operation of this website to a third party service provider; and/or&nbsp;whilst negotiating a takeover, sale, purchase or merger, financing, investment, restructuring or other proceeding involving the sale, transfer, divestiture or disclosure of all or a portion of our business or assets, and pursuant to the same.&nbsp;</li>
                    </ul>
                    <p>Transferring your Personal Information We transfer your data to third party processors within the EU and also within Asia, we will take all reasonable steps to ensure that adequate measures are in place to keep your Personal Information secure and in accordance with this Privacy Policy.</p>
                    <p><u>Security</u></p>
                    <p>We have implemented extensive technical and organisational measures to protect your data from loss, manipulation and unauthorised access. Security measures include, but are not limited to, digital and physical perimeter protection, asset management, access control and operational security controls. While we will use all reasonable endeavours to keep your Personal Information safe and secure at all times, you should note that security can never be guaranteed and we may not be liable to you unless your security is compromised due to our negligence.</p>
                    <p><u>Marketing</u></p>
                    <p>We may engage the services of third party service providers and to provide you with certain marketing services. We take steps to ensure that arrangements with these parties protect your privacy by ensuring that these parties comply strictly with our instructions when they are processing your Personal Information.</p>
                    <p><em>Marketing communications about the website</em></p>
                    <p>As a user of our website, you can tell us whether or not you wish to be contacted for marketing purposes and, if so, how we can contact you.</p>
                    <p>We may use your Personal Information to offer you personalised experiences on our website. We may deliver from time to time personalised content by using cookies to understand your behaviour and online preferences. To understand how cookies are used, please see the table above and the 'Cookies' section below.</p>
                    <p>Marketing communications about the website may contain details about:</p>
                    <ul>
                        <li>new games and promotions available to you on our website;&nbsp;and</li>
                        <li>other promotional information about our website.&nbsp;</li>
                    </ul>
                    <p>You can opt-out from receiving marketing communications from us about the website at any time. When you choose to opt-out from email, SMS or phone marketing channels about the website, you will stop receiving marketing communications.</p>
                    <p><u>Cookies</u></p>
                    <p>About Cookies: In this privacy policy, we use the expression &lsquo;cookie&rsquo; to refer to cookies and similar technologies for storing information (such as web beacons). A cookie is a simple text file that is stored on your computer or mobile (or other) device by a website&rsquo;s server, and only that server will be able to retrieve or read the contents of that cookie. Each cookie is unique to your web browser. It will contain some anonymous information, such as a unique identifier and the website name and some digits and numbers.</p>
                    <p>Almost all websites and applications you visit, including our website, will use cookies in order to improve your user experience by enabling that website and/or application to &lsquo;remember&rsquo; you, either for the duration of your visit (using a &lsquo;session cookie&rsquo;) or for repeat visits (using a &lsquo;persistent cookie&rsquo;).</p>
                    <p>Cookies do lots of different jobs that improve your use of a website or application, like letting you navigate between pages efficiently, storing your preferences, and generally improving your experience of a website. Cookies make the interaction between you and the website faster and easier. If a website or application doesn&rsquo;t use cookies, it will think you are a new visitor every time you move to a new page on the website.</p>
                    <p>Some websites, including our website and any mobile or device specific version and any related applications of our website (collectively referred to in this Cookies section as the &ldquo;Website&rdquo;), will also use cookies to enable them to target their advertising or marketing messages (based, for example, on your location and/or browsing habits).</p>
                    <p>Cookies may be set by the website you are visiting (&ldquo;first party cookies&rdquo;) or they may be set by other websites who run content on the page or application you are viewing (&ldquo;third party cookies&rdquo;).</p>
                    <p>Our Cookies: We use a number of different cookies on this Website to help us to recognise you, track your activity and improve your use and experience of the Website.</p>
                    <p>In addition, we use a number of third party service providers, who also set cookies on this Website, in order to deliver the services that they are providing to us. Such services include, but are not limited to, helping us to improve your use and experience of the Website by tracking your activity on the Website, measuring the effectiveness of the Website and the effectiveness of our marketing campaigns.</p>
                    <p>We and/or third parties may use cookies to collect or receive information from the Website and use that information to advertise to you on other websites. You can view the cookies used for such purposes on this Website and opt out of the collection and use of your information for such targeted advertising here:&nbsp;<a href="https://www.aboutads.info/choices">www.aboutads.info/choices</a>.</p>
                    <p>What to do if you don&rsquo;t want Cookies to be set: Some people find the idea of a website storing information on their computer or mobile (or other) device intrusive, particularly when this information is stored and used by a third party without them knowing. Although this is generally quite harmless, you may not, for example, want to see advertising that has been targeted to your interests. If you prefer, it is possible to block some or all cookies, or even to delete cookies that have already been set but you need to be aware that you might lose some functions of that website. Most online browsers automatically accept cookies. However, if you wish to restrict or block cookies you can modify your browser settings.</p>
                    <p><u>Retention of your Personal Information</u></p>
                    <p>We will retain your Personal Information for the period necessary for us to provide you with our services and to comply with our legal and regulatory responsibilities. Accordingly, your Personal Information will be retained for a minimum of five years following the closure of your account (if applicable) or the last contact with us from you. Where it is no longer necessary for us to process your Personal Information, we will delete it sooner. If you request that you should not be able to access our websites, mobile applications and services (i.e. self-exclude), we will retain this information for a minimum of seven years.</p>
                    <p><u>Updating Your Personal Information</u></p>
                    <p>You can update your Personal Information at any time via your account. We request that where your Personal Information changes you update your account as soon as possible.</p>
                    <p><u>Your Rights</u></p>
                    <p>You have the following rights in relation to your Personal Information:</p>
                    <ul>
                        <li>right to access your Personal Information as held by us (also known as a subject access request);</li>
                        <li>right to receive certain Personal Information in machine-readable format;</li>
                        <li>right to have inaccurate Personal Information rectified;</li>
                        <li>where we have specifically requested your consent to process your Personal Information and have no other lawful basis to process it, you have the right to withdraw this consent;</li>
                        <li>right to have certain Personal Information erased where it is no longer necessary for us to process it, where you have withdrawn your consent pursuant to the paragraph above, where you have objected pursuant to the paragraph below, where your Personal Information has been unlawfully processed, or where erasing your Personal Information is required in accordance with a legal obligation;</li>
                        <li>right to object to processing where our lawful basis for processing it is that it is in our legitimate interests, but please note that we may still process your Personal Information where there are other relevant lawful bases or where we have compelling grounds to continue processing your Personal Information in our interests which are not overridden by your rights, interests or freedoms;&nbsp;</li>
                        <li>right to request an explanation of the logic involved where we make decisions about you solely through automated means;&nbsp;</li>
                        <li>right to complain to your national data protection supervisory authority;&nbsp;and</li>
                        <li>right to object to direct marketing, which can be done by opting-out of direct marketing either through your account or by opting out via the communication itself. You also have a right to object to any profiling to the extent that it relates to direct marketing only.&nbsp;</li>
                    </ul>
                    <p>If you are unsure about your rights or are concerned about how your Personal Information may be processed, you should contact your national data protection supervisory authority.</p>
                    <p>If you would like to exercise any of your rights then you can do so by contacting us as described below. Please be aware that while we will try to accommodate any request you make in respect of your rights, they are not necessarily absolute rights. This means that we may have to refuse your request or may only be able to comply with it in part.</p>
                    <p>Where you make a request in respect of your rights we will require proof of identification. We may also ask that you clarify your request. We will aim to respond to any request within one month of verifying your identity. If we receive repeated requests, or have reason to believe requests are being made unreasonably, we reserve the right not to respond.</p>
                    <p><u>Contact Us</u></p>
                    <p>If you have any questions regarding our use of your Personal Information, or you would like to exercise any of your rights, please contact <u>dataprotection@hyperino.social</u></p>

                </div>
            </div>
            {/*<BackButton/>*/}
        </div>
    </div>
)

export default PrivacyPolicyEn
