import React from 'react'
import {Helmet} from "react-helmet";
import BackButton from "../components/BackButton";
import OwlCarousel from 'react-owl-carousel';
import GameSlider from '../components/GameSlider';
import {FaExpandArrowsAlt} from 'react-icons/fa'
import games from '../assets/social-games';
import axios from 'axios'
import language from "../assets/language";
import ReactHtmlParser from 'react-html-parser';

class Gamepage extends React.Component {
    gameId;

    owlCarouselOptions = {
        items: 4,
        margin: 20,
        stagePadding: 20,
        nav: false,
        rewind: true,
        autoplay: false,
        responsive: {
            0: {
                items: 1,
                nav: true
            },
            720: {
                items: 2,
                nav: true
            },
            1000: {
                items: 3,
                nav: true
            },
        },
    }

    constructor(props) {
        super(props);

        this.gameId = props.match.params.id
        this.state = {
            gameDetails: games.find(el => el.id === this.gameId),
            errorMessage: "",
            addClass: false,
            countryCode: ""
        }
    }

    toggle() {
        this.setState({addClass: !this.state.addClass});
    }


    componentDidMount() {
        window.scrollTo(0, 0)
        this.getGeoInfo();
    }

    getGeoInfo = () => {
        axios.get('https://ipapi.co/json/').then((response) => {
            let data = response.data;
            this.setState({
                countryCode: data.country_code
            });
        }).catch((error) => {
            this.setState({
                countryCode: 'error'
            });
        });
    };

    showIframe = () => {

        let toggleClass = ["toggle-full"];

        if(this.state.addClass) {
            toggleClass.push('active');
        }

        return (
            <div className="container">
                <div className={"iframe-game " + toggleClass.join(' ')}>
                    <BackButton/>
                    <iframe
                        src={this.state.gameDetails.gameUrl}
                        frameBorder='0'
                        title='Hyperino'
                    ></iframe>
                    <a className={'expand-game ' + toggleClass.join(' ')} onClick={this.toggle.bind(this)}><FaExpandArrowsAlt/></a>
                </div>
            </div>
        )
    }

    showGameSlider = () => {
        window.getSelection().empty()
        return games.slice(0, 5).map((elem, index) => (
                <GameSlider
                    key={index}
                    thumbnailUrl={elem.thumbnailUrl}
                    id={elem.id}
                />
        ))
    }

    render() {
        let toggleClass = ["toggle-full"];
        let content = '';
        let gContent = this.state.gameDetails.gameContent;

        if(this.state.addClass) {
            toggleClass.push('active');
        }

        if(this.state.countryCode == "DE"){
           content = <div className="game__m__container pb-5">
               <Helmet>
                   <title>{this.state.gameDetails.metaTitle}</title>
                   <meta name="description" content={this.state.gameDetails.metaDescription}/>
                   <link rel="canonical" href={`https://hyperino.social/game/` + this.gameId} />

               </Helmet>

               <div className={'gameplay__container ' + toggleClass.join(' ')} style={{color: 'white'}}>
                   <div>
                       {this.showIframe()}

                       <div className='container-game-info'>
                           <div className="row mb-4">
                               <div className="col-md-12 col-lg-8 ml-25">
                                   <h1 className="game-title">{this.state.gameDetails.name}</h1>
                                   {/*<p className="game-text">*/}
                                   {/*    {this.state.gameDetails.shortDescription}*/}
                                   {/*</p>*/}
                                   <p className="game-text">
                                       {ReactHtmlParser(gContent)}
                                   </p>
                               </div>
                           </div>
                       </div>
                   </div>;
               </div>

               <div className='container-carousel'>
                   <OwlCarousel className='owl-theme' {...this.owlCarouselOptions}>
                       {this.showGameSlider()}
                   </OwlCarousel>
               </div>
           </div>
        }else{
            content =  <div className="game__m__container pb-5">
                <Helmet>
                    <title>{this.state.gameDetails.metaTitle}</title>
                    <meta name="description" content={this.state.gameDetails.metaDescription} />
                    <link rel="canonical" href={`https://hyperino.social/game/` + this.gameId} />

                </Helmet>

                <div className={'gameplay__container ' + toggleClass.join(' ')} style={{color: 'white'}}>
                    <div className='container-game-info'>
                        <div className="row mb-75 text-center">
                            <h1 className={'ml-auto mr-auto'}>{language.error_country_game_page}</h1>
                        </div>
                        <div className="row mb-4">
                            <div className="col-md-12 col-lg-8 ml-25">
                                <h1 className="game-title">{this.state.gameDetails.name}</h1>
                                {/*<p className="game-text">*/}
                                {/*    {this.state.gameDetails.shortDescription}*/}
                                {/*</p>*/}
                                <p className="game-text">
                                    {ReactHtmlParser(gContent)}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }

        return (
            content
        )
    }
}

export default Gamepage
