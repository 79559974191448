import React, {Component} from "react";
import {useState} from "react";
import {FaMinus, FaPlus} from "react-icons/fa";

const FaqTemplate = ({title, description, id}) => {
    const [toggleElement, setToggleHeader] = useState(false);
    return (
        <div className={'faq'}>
            <div className={`faq-header ${toggleElement ? 'faq-open' : ''}`}
                 onClick={() => setToggleHeader((prev) => !prev)} key={id}>
                <span><span>Q</span> {title}</span>
                <FaPlus/> <FaMinus/>
            </div>
            <div className={`faq-content ${toggleElement ? 'faq-open' : ''}`}>
                {description}
            </div>
        </div>
    );
};

const FaqContent = [
    {
        'title': 'Welches ist das beste Casino-Spiel zum kostenlosen Spielen?',
        'description': 'Hyperino casino ist definitiv eine der seriösesten Online-Casino-Seiten, die vollständig lizenziert ist und die beste Technologie verwendet, um Ihre Sicherheit zu gewährleisten. Außerdem finden Sie Demo-Versionen unserer Top casino spiele kostenlos ohne anmeldung. Einige der beliebtesten Casino-Spiele, die Sie kostenlos spielen können, sind Book of Dead, Legacy of Dead, Jammin Jars und The Dog House.'
    }, {
        'title': 'Wie fange ich an zu spielen casino spiele kostenlos spielen?',
        'description': ' Gehen Sie einfach auf die Website von Hyperino casino, wählen Sie Ihr Spiel und beginnen Sie zu spielen. Sie müssen sich nicht registrieren oder eine Software herunterladen. '
    }, {
        'title': 'Kann ich Geld gewinnen, wenn ich casino spiele kostenlos ohne anmeldung spiele?',
        'description': 'Nein, Sie können kein Geld gewinnen, wenn sie casino spiele kostenlos spielen. Sie können diese Spiele jedoch nutzen, um Ihre Fähigkeiten zu üben und mehr darüber zu erfahren, wie das Spiel funktioniert. Sobald Sie sich sicher fühlen, können Sie um echtes Geld spielen.'
    }, {
        'title': 'Welches ist das beste Casinospiel, das man um echtes Geld spielen kann?',
        'description': 'Welches Casino-Spiel am besten geeignet ist, um um echtes Geld zu spielen, hängt von Ihren persönlichen Vorlieben ab. Wenn Sie auf der Suche nach Nervenkitzel sind, empfehlen wir Ihnen, unsere Top-Spielautomaten auszuprobieren. Wenn Sie eher an Strategie interessiert sind, dann sollten Sie vielleicht Blackjack oder Poker ausprobieren. Was auch immer Sie bevorzugen, wir haben das Richtige für Sie! Wenn Sie sich mit den Demospielen vertraut gemacht haben, können Sie jederzeit auf die Hyperino casino Seite gehen und nach Echtgeld-Versionen suchen!'
    }, {
        'title': 'Ist es sicher, Casino-Spiele online zu spielen?',
        'description': 'Ja, es ist sicher, Casino-Spiele online zu spielen, solange Sie eine seriöse Casino-Seite wie Hyperino casino wählen. Wir verwenden die neueste Technologie, um Ihre Sicherheit zu gewährleisten, während Sie unsere Spiele spielen. Sie können sich also darauf verlassen, dass Ihre Daten geschützt sind, wenn Sie bei Hyperino casino spielen.'
    }
];

export class Faq extends Component {
    render() {
        return (
            <div className='faq-section'>
                <h5>FAQs</h5>
                <div className={'faq-container'}>
                    {FaqContent.map((value, id) => {
                        return <FaqTemplate title={value.title} description={value.description} key={id}/>;
                    })}
                </div>
            </div>
        )
    }
}
