import React from 'react'
import logo from '../assets/imgs/logo.svg'
import language from '../assets/language'

const Footer = () => {
    return (
        <footer className="mt-5 mb-5">
            <div className="container container-layout">
                <div className="row">
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 order-2-m">
                        <p className="footer-text">{language.footer.column1}</p>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 ml-auto mr-auto order-3-m">
                        <p className="footer-text">{language.footer.column2}</p>
                    </div>
                    <div className="col-12 col-sm-12 col-md-3 col-lg-3 text-right order-1-m">
                        <div className="footer-logo">
                            <a href={'/'}><img src={logo}/></a>
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-12 col-sm-6 col-md-2 col-lg-3 hide-mobile"></div>
                    <div className="col-12 col-sm-6 col-md-2 col-lg-3 hide-mobile"></div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 ml-auto mr-auto order-3-m">
                        <a href="/privacy-policy-en" className="footer-link mr-4">{language.footer.policy_privacy}</a>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 ml-auto mr-auto order-3-m">
                        <a href="/terms-and-conditions" className="footer-link">{language.footer.terms_and_conditions}</a>
                    </div>
                </div>
                <div className="row text-center">
                    <p className="copy-text text-center">© 2022 Hyperino Social</p>
                </div>
            </div>
        </footer>
    )
}

export default Footer
