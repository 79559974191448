import React, {Component} from 'react'
import {Modal, Form} from 'react-bootstrap'
import {FaFacebookSquare} from 'react-icons/fa'

import {auth, signInWithFacebook} from "../../firebase/firebase.utils";
import language from "../../assets/language";

const regExp = RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
)

class LoginModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            isError: {
                email: '',
                password: ''
            }
        }
    }

    formValChange = e => {
        e.preventDefault();
        const {name, value} = e.target;
        let isError = {...this.state.isError};

        switch (name) {
            case "email":
                isError.email = regExp.test(value) ? "" : language.errors.incorrect_email;
                break;
            case "password":
                isError.password = value.length < 6 ? language.errors.six_characters : "";
                break;
            default:
                break;
        }

        this.setState({
            isError,
            [name]: value
        })
    };

    onSubmit = async e => {
        e.preventDefault();
        let isError = {...this.state.isError};
        const { email, password } = this.state;

        if(email === ""){
            isError.email = language.errors.field_required
        }

        if(password === ""){
            isError.password = language.errors.field_required
        }

        try {
            await auth.signInWithEmailAndPassword(email, password);
            this.setState({
                email: '',
                password: ''
            })
            this.props.onHide();

        } catch (error) {
            if (error.code === "auth/user-not-found") {
                isError.email = language.errors.user_not_found;
            }else if(error.code === "auth/wrong-password"){
                isError.email = language.errors.wrong_password;
            }else{
                isError.email = language.errors.error_occured;
            }
        }

        this.setState({
            isError
        })
    };

    onSubmitFacebook = () => {
        signInWithFacebook();
        this.props.onHide();
    }

    render() {
        const {setRegisterModal, setResetPasswordModal, ...otherProps} = this.props;
        const {isError} = this.state;

        return (
            <Modal
                {...otherProps}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modal-register"
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <h2 className='title-modal'>{language.buttons.login_and_play}</h2>
                    <Form onSubmit={this.onSubmit} noValidate>
                        <Form.Group>
                            <Form.Control type="email" name="email"
                                          defaultValue={this.state.email}
                                          onBlur={this.formValChange} placeholder={language.email}
                                          className={isError.email.length > 0 ? "is-invalid form-control input-modal" : "form-control input-modal"}
                            />
                            {isError.email.length > 0 && (
                                <span className="invalid-feedback">{isError.email}</span>
                            )}
                        </Form.Group>

                        <Form.Group>
                            <Form.Control type="password" name="password"
                                          defaultValue={this.state.password}
                                          className={isError.password.length > 0 ? "is-invalid form-control input-modal" : "form-control input-modal"}
                                          onBlur={this.formValChange}
                                          placeholder={language.password}/>
                            {isError.password.length > 0 && (
                                <span className="invalid-feedback">{isError.password}</span>
                            )}
                        </Form.Group>

                        <div className="row">
                            <div className="col-md-6 align-self-center">
                                <a href="#" onClick={setResetPasswordModal} className="forgot-pass">{language.forgot_password}</a>
                            </div>
                            <div className="col-md-6 text-right">
                                <button type="submit" className="login-btn">{language.buttons.login}</button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-right">
                                <a href="#" onClick={this.onSubmitFacebook} className="login-Facebook"><span className="text-Facebook">{language.login_Facebook}</span> <span className='i-Facebook'><FaFacebookSquare/></span></a>
                            </div>
                        </div>
                        <div className="row row-out">
                            <p className="account-out">{language.account_yet}</p>
                            <a className="link-join"
                               onClick={setRegisterModal}>{language.join}</a>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        );
    }
}

export default LoginModal


